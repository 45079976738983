import '@/lib/firebase';
import {
  ActionIcon,
  Avatar,
  Button,
  Divider,
  Loader,
  MantineProvider,
  Menu,
  NavLink,
  Overlay,
  rem,
  Tooltip,
  useMantineColorScheme,
} from '@mantine/core';
import '@mantine/core/styles.css';
import { ModalsProvider } from '@mantine/modals';
import { notifications, Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import { createBrowserRouter, Link, Outlet, RouterProvider, useLocation } from 'react-router-dom';

import {
  IconCalendarEvent,
  IconHome2,
  IconLogout,
  IconMasksTheaterOff,
  IconMenu2,
  IconMoon,
  IconQuestionMark,
  IconSettingsAutomation,
  IconSun,
  IconUsersGroup,
  IconYoga,
} from '@tabler/icons-react';
import { getAuth } from 'firebase/auth';
import { lazy, Suspense, useEffect } from 'react';
import './app.css';
import { AdminOrOwnerOnly } from './components/AdminOnly';
import { LoginComponent } from './components/Login/Login';
import { ScreenLoader } from './components/ScreenLoader';
import { Show } from './components/Show';
import './full-calendar.css';
import { appStore, closeSidebar, stopImperonation, toggleSidebar } from './stores/app.store';
import { theme } from './theme';
import { useIsMobile } from '@/lib/hooks/is-mobile';
import clsx from 'clsx';

const fakeDelay = false;
const fakeDelayTime = 600;

let customLazy = (load: () => Promise<{ default: React.ComponentType<any> }>) => {
  const delayed = () => {
    return new Promise<{ default: React.ComponentType<any> }>(async (res, rej) => {
      setTimeout(async () => {
        res(await load());
      }, fakeDelayTime);
    });
  };
  return lazy(delayed);
};

if (!fakeDelay) {
  customLazy = lazy;
}

const prefetchHome = () => import('./pages/Home.page');
const HomePage = customLazy(() => prefetchHome().then((m) => ({ default: m.HomePage })));

const prefetchReservations = () => import('./pages/Reservations/Reservations.page');
const ReservationsPage = customLazy(() =>
  prefetchReservations().then((m) => ({ default: m.ReservationsPage }))
);

const prefetchTimeSlots = () => import('./pages/TimeSlots/TimeSlots.page');
const TimeSlotPage = customLazy(() =>
  prefetchTimeSlots().then((m) => ({ default: m.TimeSlotsPage }))
);

const prefetchClients = () => import('./pages/Clients/Clients.page');
const ClientsPage = customLazy(() => prefetchClients().then((m) => ({ default: m.ClientsPage })));

const prefetchSchedule = () => import('./pages/Schedule/Schedule.page');
const SchedulePage = customLazy(() =>
  prefetchSchedule().then((m) => ({ default: m.SchedulePage }))
);

const prefetchProfile = () => import('./pages/Profile/Profile.page');
const ProfilePage = customLazy(() => prefetchProfile().then((m) => ({ default: m.ProfilePage })));

const prefetchUsers = () => import('./pages/Users/Users.page');
const User2Page = customLazy(() => prefetchUsers().then((m) => ({ default: m.UsersPage })));

const prefetchSiteManagement = () => import('./pages/SiteManagement');
const SiteManagementPage = customLazy(() =>
  prefetchSiteManagement().then((m) => ({ default: m.SiteManagementPage }))
);

const prefetchRanking = () => import('./pages/Ranking.page');
const RankingPage = customLazy(() => prefetchRanking().then((m) => ({ default: m.RankingPage })));

async function prefetchAll() {
  void prefetchHome();
  void prefetchReservations();
  void prefetchSchedule();
  void prefetchProfile();
  void prefetchUsers();
  void prefetchSiteManagement();
  void prefetchRanking();
}

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    errorElement: <div>error...</div>,
    children: [
      {
        path: '/',
        index: true,
        element: <HomePage />,
      },
      {
        path: '/schedule',
        element: <SchedulePage />,
      },
      {
        path: '/profile',
        element: <ProfilePage />,
      },
      {
        path: '/users',
        element: <User2Page />,
      },
      {
        path: '/site-management',
        element: <SiteManagementPage />,
      },
      {
        path: '/reservations',
        element: <ReservationsPage />,
      },
      {
        path: '/time-slots',
        element: <TimeSlotPage />,
      },
      {
        path: '/clients',
        element: <ClientsPage />,
      },
      {
        path: '/ranking',
        element: <RankingPage />,
      },
    ],
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}

export function AppLayout() {
  const userLoggedIn = appStore((store) => !!store.user);
  const appLoading = appStore((store) => store.appLoading);

  useEffect(() => {
    prefetchAll();
  }, []);

  let body: React.ReactNode;
  if (appLoading) {
    body = (
      <div className="w-screen h-screen flex justify-center items-center">
        <Loader />
      </div>
    );
  } else if (!userLoggedIn) {
    body = <LoginComponent />;
  } else {
    body = <DashboardBody />;
  }

  return (
    <MantineProvider theme={theme} defaultColorScheme="auto">
      <ModalsProvider>
        <Notifications />
        {body}
      </ModalsProvider>
    </MantineProvider>
  );
}

function DashboardBody() {
  return (
    <div className="flex min-h-screen max-h-screen max-w-screen">
      <DashboardSidebar />

      <Divider orientation="vertical" />

      <div className="flex-1 flex flex-col">
        <header className="min-h-[var(--header-height)] max-h-[var(--header-height)] p-4 flex justify-between items-center">
          <div className="flex flex-1 gap-4 items-center">
            <MobileSidebarToggleButton />
            <div className="flex flex-1" id="header-left"></div>
          </div>
          <div className="flex flex-1" id="header-middle"></div>
          <div className="flex flex-1" id="header-right"></div>
        </header>
        <Divider />
        <main className="flex flex-col max-h-[calc(100vh-var(--header-height))] min-h-[calc(100vh-var(--header-height))] overflow-scroll">
          <Suspense fallback={<ScreenLoader />}>
            <Outlet></Outlet>
          </Suspense>
        </main>
      </div>
    </div>
  );
}

function MobileSidebarToggleButton() {
  const isMobile = useIsMobile();

  if (!isMobile) return null;

  return (
    <ActionIcon
      variant="subtle"
      onClick={() => {
        toggleSidebar();
      }}
    >
      <IconMenu2 />
    </ActionIcon>
  );
}

function DashboardSidebar() {
  const isMobile = useIsMobile();
  const siderbarOpen = appStore((s) => s.siderbarOpen);

  return (
    <>
      {siderbarOpen ? (
        <Overlay
          onClick={() => {
            closeSidebar();
          }}
        />
      ) : null}
      <aside
        className={clsx(
          'w-[var(--sidebar-width)]  flex flex-col transition-transform duration-500',
          {
            'absolute z-[201] bg-black h-screen border-r border-r-slate-800 shadow-appbrand':
              isMobile,
            '-translate-x-[100%]': isMobile && !siderbarOpen,
          }
        )}
      >
        {/* title */}
        <div className="h-[var(--header-height)] flex flex-col items-center justify-center">
          <div className="flex items-center gap-2">
            <MobileSidebarToggleButton />
            <Link to="/">
              <p className="shoju">Determinacja</p>
            </Link>
          </div>
        </div>
        <Divider />
        {/* content */}
        <div className="flex flex-col flex-1 p-1 gap-1">
          <Links />
        </div>
        {/* bottom */}
        <div className="p-1 flex flex-col gap-1">
          <GuideButton />
          <ImpersonateUserButton />
          <Divider />
          <ProfileButton />
        </div>
      </aside>
    </>
  );
}

function ImpersonateUserButton() {
  const impersonatedUserId = appStore((s) => s.impersonatedUserId);

  async function stopImper() {
    stopImperonation();
    notifications.show({
      title: 'Pomyślnie zakończono impersonację',
      message: '',
      color: 'green',
    });
  }

  return (
    <Show when={!!impersonatedUserId}>
      <Tooltip label="Zakończ impersontację użytkownika">
        <Button
          variant="transparent"
          color="dark.1"
          leftSection={<IconMasksTheaterOff style={{ width: rem(14), height: rem(14) }} />}
          onClick={stopImper}
          className="text-wrap"
        >
          Zakończ impersonację
        </Button>
      </Tooltip>
    </Show>
  );
}

function GuideButton() {
  return (
    <Button variant="transparent" c="dark.1" rightSection={<IconQuestionMark />}>
      Pomoc
    </Button>
  );
}

function ProfileButton() {
  const { toggleColorScheme, colorScheme } = useMantineColorScheme();
  const user = appStore((store) => store.user);

  function logout() {
    getAuth().signOut();
  }

  return (
    <Menu shadow="md" width={190} trigger="hover">
      <Menu.Target>
        <Button
          leftSection={
            <Avatar
              src={user?.profile.avatarUrl}
              style={{ maxWidth: rem(32), maxHeight: rem(32) }}
            />
          }
          className="w-full flex justify-start"
          variant="subtle"
          color="violet"
        >
          {user?.name}
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        {/* <Menu.Label>Application</Menu.Label>
        <Menu.Item leftSection={<IconSettings style={{ width: rem(14), height: rem(14) }} />}>
          Settings
        </Menu.Item>
        <Menu.Item leftSection={<IconMessageCircle style={{ width: rem(14), height: rem(14) }} />}>
          Messages
        </Menu.Item>
        <Menu.Item leftSection={<IconPhoto style={{ width: rem(14), height: rem(14) }} />}>
          Gallery
        </Menu.Item>
        <Menu.Item
          leftSection={<IconSearch style={{ width: rem(14), height: rem(14) }} />}
          rightSection={
            <Text size="xs" c="dimmed">
              ⌘K
            </Text>
          }
        >
          Search
        </Menu.Item>

        <Menu.Divider /> */}

        <Menu.Label>Ustawienia</Menu.Label>
        <Menu.Item
          leftSection={
            colorScheme === 'dark' ? (
              <IconSun style={{ width: rem(14), height: rem(14) }} />
            ) : (
              <IconMoon style={{ width: rem(14), height: rem(14) }} />
            )
          }
          onClick={toggleColorScheme}
        >
          Zmień wygląd
        </Menu.Item>
        <Menu.Item
          leftSection={<IconLogout style={{ width: rem(14), height: rem(14) }} />}
          onClick={logout}
        >
          Wyloguj
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

function Links() {
  const { pathname: route } = useLocation();

  return (
    <div>
      <NavLink
        onClick={() => {
          closeSidebar();
        }}
        to="/"
        component={Link}
        active={route === '/'}
        label="Strona główna"
        leftSection={<IconHome2 size="1rem" stroke={1.5} />}
      ></NavLink>
      <NavLink
        onClick={() => {
          closeSidebar();
        }}
        to="/schedule"
        component={Link}
        active={route === '/schedule'}
        label="Grafik"
        leftSection={<IconCalendarEvent size="1rem" stroke={1.5} />}
      ></NavLink>

      <NavLink
        onClick={() => {
          closeSidebar();
        }}
        to="/reservations"
        component={Link}
        active={route === '/reservations'}
        label="Rezerwacje"
        leftSection={<IconCalendarEvent size="1rem" stroke={1.5} />}
      ></NavLink>

      <NavLink
        onClick={() => {
          closeSidebar();
        }}
        to="/time-slots"
        component={Link}
        active={route === '/time-slots'}
        label="Treningi"
        leftSection={<IconYoga size="1rem" stroke={1.5} />}
      ></NavLink>

      <NavLink
        onClick={() => {
          closeSidebar();
        }}
        to="/clients"
        component={Link}
        active={route === '/clients'}
        label="Klienci"
        leftSection={<IconUsersGroup size="1rem" stroke={1.5} />}
      ></NavLink>

      <AdminOrOwnerOnly>
        <NavLink
          onClick={() => {
            closeSidebar();
          }}
          to="/users"
          component={Link}
          active={route === '/users'}
          label="Trenerzy"
          leftSection={<IconUsersGroup size="1rem" stroke={1.5} />}
        ></NavLink>
      </AdminOrOwnerOnly>
      <NavLink
        onClick={() => {
          closeSidebar();
        }}
        to="/profile"
        component={Link}
        active={route === '/profile'}
        label="Profil"
        leftSection={<IconCalendarEvent size="1rem" stroke={1.5} />}
      ></NavLink>
      <AdminOrOwnerOnly>
        <NavLink
          onClick={() => {
            closeSidebar();
          }}
          to="/site-management"
          component={Link}
          active={route === '/site-management'}
          label="Zarządzanie stroną"
          leftSection={<IconSettingsAutomation size="1rem" stroke={1.5} />}
        ></NavLink>
      </AdminOrOwnerOnly>
    </div>
  );
}
